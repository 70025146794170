@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.link {
  display: inline-flex;
  margin-top: 16px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2em;
  cursor: pointer;
  color: a.$black;
  text-decoration: none;

  &--alt {
    color: a.$white;
  }

  &--arrow {
    .link__label {
      background: transparent url(/assets/images/icons/arrow.svg) no-repeat 4px
        2px;
      padding-left: 30px;
      padding-bottom: 8px;
    }
  }

  &__icon {
    margin-right: 8px;
  }

  &__label {
    border-bottom: 2px solid a.$salmon;
    padding-bottom: 3px;

    @include a.tran;
  }

  &__mt-0 {
    margin-top: 0;
  }

  &:hover {
    .link__label {
      border-bottom-color: a.$salmon;

      @include a.mqmin('mpbile') {
        border-bottom-color: a.$black;
      }
    }
  }
}

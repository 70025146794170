@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.price {
  color: a.$white;
  @include a.font(primary, 200);

  &__heading {
    .heading__pre {
      margin-bottom: 10px;
    }
  }

  &__main {
    display: flex;
    align-items: flex-end;
    font-size: 28px;
    line-height: 36px;
  }

  &__sub {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 25px;
    margin-left: 5px;
  }
}

@use './variables';

@mixin mq($key) {
  $size: map-get(variables.$breakpoints, $key);

  @media only screen and (max-width: $size) {
    @content;
  }
}

@mixin mqmin($key) {
  $size: map-get(variables.$breakpoints, $key);

  @media only screen and (min-width: $size) {
    @content;
  }
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.legend {
    margin-bottom: 30px;
    font-weight: 200;

    &__main {
        margin-bottom: 20px;
        font-size: 24px;
    }

    &__additional {
        line-height: 26px;
        font-size: 15px;
    }
}

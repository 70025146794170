@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.payment-field {
  display: flex;
  padding: 0 30px 30px;
  flex-wrap: wrap;

  @include a.mqmin('desktop') {
    padding: 0;
    margin-bottom: 32px;
  }

  &__card-number {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
    position: relative;

    @include a.mqmin('desktop') {
      flex: 0 0 45%;
      margin-bottom: 0;
    }

    .secure-field--input {
      padding-left: 55px;

      &-container {
        &::before {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          width: 55px;
          height: 48px;
          pointer-events: none;
          background: white;
          background-image: url('../../assets/images/icons/credit-card.svg');
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 0;
        }
      }
    }

  }

  &__expiry,
  &__csv {
    flex: 0 0 50%;
    max-width: 100%;
    border-radius: 0;

    @include a.mqmin('desktop') {
      flex: 1;
    }

    @include a.mqmin('desktop') {
      .field {
        padding-left: 0;
      }
    }
  }

  .field__wrap__validation-message {
    width: 100%;
  }
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.navbar {
  display: flex;

  &__step {
    display: flex;
    align-items: center;
    color: a.$white;
    font-weight: 200;
    text-decoration: none;
    font-size: 14px;
    margin-left: 10px;

    @include a.mqmin('tablet') {
      font-size: 16px;
      margin-left: 20px;
    }

    @include a.mqmin('desktop') {
      font-size: 20px;
      margin-left: 40px;
    }

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid a.$white;
      background: transparent;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      flex: 0 0 38px;

      @include a.mqmin('desktop') {
        width: 46px;
        height: 46px;
        flex: 0 0 46px;
        margin-right: 17px;
      }
    }

    &-label {
      display: none;
      margin-left: 10px;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 2px;

      @include a.mqmin('tablet') {
        display: inline-block;
      }
    }

    &--active,
    &--in-progress {
      .navbar__step {
        &-number {
          background: a.$white;
          color: a.$olive;
        }
      }
    }

    &--in-progress {
      .navbar__step {
        &-number {
          background: rgba(a.$white, 0.25);
          color: a.$white;
        }

        &-label {
          @include a.mq('tablet') {
            display: block;
          }
        }
      }
    }
  }
}

@use '../../assets/scss/abstracts/_abstracts-dir'as a;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 250ms ease;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &--show {
    opacity: 1;
    visibility: visible;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(a.$black, .6);
  }

  &__close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }

  &__body {
    position: relative;
    z-index: 1001;
    top: 75px;
    padding: 36px;
    background: a.$olive;
    box-shadow: 0 5px 30px 0 rgba(a.$black, .4);
    border-radius: 10px;
    width: 90%;
    max-width: 950px;
    max-height: calc(90vh - 40px);
    overflow-x: auto;

    .heading__main {
      font-weight: lighter;
      text-align: center;
    }

    .button {
      background: a.$salmon;

      &:hover {
        background: transparent;
        color: a.$white;
      }
    }
  }

  &__content {
    margin: 30px 0;
    color: a.$white;
  }

  @include a.mq('tablet') {
    &__trigger {
      margin-bottom: 20px;
    }
  }
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

$heading-lg-size: 91px;
$heading-lg-height: 96px;

$heading-mlg-size: 60px;
$heading-mlg-height: 68px;

$heading-md-size: 48px;
$heading-md-height: 49px;

$heading-xs-size: 24px;
$heading-xs-height: 27px;

$heading-xxs-size: 18px;
$heading-xxs-height: 21px;

.heading {
  &__pre {
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 28px;
  }

  &__main {
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 35px;
    @include a.font(secondary, normal);

    &--lg {
      font-size: $heading-lg-size;
      line-height: $heading-lg-height;

      @include a.mq('tablet') {
        font-size: $heading-lg-size * 0.7;
        line-height: $heading-lg-height * 0.7;
      }
    }

    &--mlg {
      font-size: $heading-mlg-size;
      line-height: $heading-mlg-height;

      @include a.mq('tablet') {
        font-size: $heading-mlg-size * 0.8;
        line-height: $heading-mlg-height * 0.85;
      }
    }

    &--md {
      font-size: $heading-md-size;
      line-height: $heading-md-height;

      @include a.mq('tablet') {
        font-size: $heading-md-size * 0.8;
        line-height: $heading-md-height * 0.85;
      }
    }

    &--xs {
      font-size: $heading-xs-size;
      line-height: $heading-xs-height;
    }

    &--xxs {
      font-size: $heading-xxs-size;
      line-height: $heading-xxs-height;
    }

    &--xxs-margin {
      margin-bottom: 10px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 161%;
    @include a.font(primary, 200);
  }

  &__wrapper {
    color: a.$white;

    @include a.mq('desktop') {
      padding: 30px;
    }

    & + .heading__wrapper {
      margin-top: 30px;
    }

    &--tight + .heading__wrapper {
      margin-top: 0;
    }

    &--secondary {
      .heading {
        &__main {
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 10px;

          @include a.font(primary, 200);
        }
      }
    }

    &--dark {
      .heading {
        &__main,
        &__text {
          color: a.$black;
        }

        &__main {
          font-size: 22px;
          line-height: 22px;
          margin-bottom: 10px;

          @include a.font(primary, 200);
        }

        &__text {
          margin-bottom: 20px;
        }
      }
    }

    &--lg {
      .heading {
        &__pre {
          border-bottom: none;
          padding-bottom: none;
        }

        &__main {
          position: relative;

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -30px;
            width: 2px;
            height: 100%;
            background: a.$salmon;
          }
        }
      }
    }
  }
}

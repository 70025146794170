@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.date-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;

  @include a.mqmin('tablet') {
    font-size: 20px;
    line-height: 20px;
  }

  @include a.mqmin('desktop') {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  &__inner {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;

    @include a.mq('desktop') {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__date-trigger {
    display: flex;
  }

  &__date-value {
    margin-right: 15px;

    @include a.mqmin('tablet') {
      font-size: 24px;
    }
  }

  &__key {
    display: flex;
    align-items: center;

    @include a.mqmin('tablet') {
      margin-left: 50px;
    }

    &__icon {
      width: 32px;
      height: 32px;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      margin-right: 7px;
    }

    &__content {
      max-width: 210px;
      font-size: 12px;
      line-height: 17px;
      color: #7a7585;
    }
  }

  &__error {
    margin-bottom: 30px;
    color: a.$red;
  }

  @include a.mqmin('tablet') {
    &__label {
      font-size: 24px;
    }
  }
}

.react-calendar__wrap {
  position: absolute;
  top: 45px;
  right: 0;
  width: 405px;
  line-height: 1.125em;
  margin-bottom: 50px;
  z-index: 2;
  background: a.$white;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.22), 0 4px 12px 2px rgba(0, 0, 0, 0.15);

  @include a.mqmin('tablet') {
    padding: 30px;
    width: 727px;
  }

  @include a.mq('mobile') {
    margin-left: auto;
    margin-right: auto;
    left: -20px;
    max-width: 112%;
  }

  abbr {
    border: none;
    text-decoration: none;

    @include a.font(primary, normal);
  }

  .day-has-event {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
  }
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;

  @include a.mqmin('tablet') {
    padding: 0 50px;
  }
}
.react-calendar--doubleView
  .react-calendar__viewContainer
  .react-calendar__month-view {
  @include a.mqmin('tablet') {
    width: 50%;
    margin: 0;
    max-width: 272px;
  }
}

@include a.mq('tablet') {
  .react-calendar__month-view + .react-calendar__month-view,
  .react-calendar__navigation__label__labelText--to {
    display: none;
  }
}

.react-calendar--doubleView .react-calendar__viewContainer {
  .react-calendar__month-view + .react-calendar__month-view {
    margin-left: 56px;
  }
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  margin-bottom: 25px;
}

.react-calendar__navigation button {
  background: none;
  margin: 0;
  padding: 0;

  @include a.font(primary, normal);
}

.react-calendar__navigation__label {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 10px;
  color: a.$black;
  letter-spacing: 1.5px;

  &__divider {
    display: none;
  }

  &__labelText {
    text-align: center;
    width: 100%;

    @include a.mqmin('tablet') {
      flex: 0 0 calc(50% - 25px);
      width: calc(50% - 25px);
    }
  }
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__navigation__arrow {
  position: absolute;
  top: 15px;

  &.react-calendar__navigation__prev-button {
    left: 15px;
  }

  &.react-calendar__navigation__next-button {
    right: 15px;
  }

  @include a.mqmin('tablet') {
    top: 50%;

    &.react-calendar__navigation__prev-button {
      left: 30px;
    }

    &.react-calendar__navigation__next-button {
      right: 30px;
    }
  }


  &:disabled {
    opacity: 0.25;
  }
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 16px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile:disabled {
  color: #E5E5E5;
}

.react-calendar__tile:not(:disabled) {
  color: a.$black;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.react-calendar {
  &__tile {
    max-width: 100%;
    text-align: center;
    padding: 0 0.5em;
    background: transparent;
    height: 38px;
    margin: 0 0 10px 0;
    overflow: visible !important;
    position: relative;

    @include a.mq('tablet') {
      height: 8vw !important;
    }

    @include a.mq('mobile') {
      height: 11vw !important;
    }

    @include a.mq('xs') {
      height: 13vw !important;
    }

    &--active,
    &--hasActive,
    &--active:enabled:hover &--active:enabled:focus,
    &--hasActive:enabled:hover &--hasActive:enabled:focus {
      background: a.$calendarBackground;
      color: a.$white;
    }

    &--active {
      pointer-events: none;
      cursor: not-allowed;
    }

    &--rangeStart,
    &--rangeEnd {
      position: relative;
      z-index: 1;
      background: a.$calendarHighlight;

      abbr {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: a.$calendarHighlight;
          border-radius: 50%;
          z-index: -1;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: a.$calendarBackground;
          z-index: -2;
        }
      }
    }

    &--rangeStart {
      border-radius: 50% 0 0 50%;

      abbr:after {
        border-radius: 50% 0 0 50%;
      }
    }

    &--rangeEnd {
      border-radius: 0 50% 50% 0;
      margin-left: -1px;

      abbr:after {
        border-radius: 0 50% 50% 0;
      }
    }

    &--rangeBothEnds {
      border-radius: 50%;

      abbr:after {
        border-radius: 50%;
      }
    }

    &--range.react-calendar__month-view__days__day--weekend
      + .react-calendar__month-view__days__day--weekend {
      border-radius: 0 50% 50% 0;
      margin-left: -1px;
      height: 38.5px;
    }

    &--range.react-calendar__month-view__days__day--weekend
      + .react-calendar__month-view__days__day--weekend
      + .react-calendar__tile--range {
      border-radius: 50% 0 0 50%;
      margin-right: -1px;
    }
  }
}

.special-event {
  position: absolute;
  left: 50%;
  bottom: 45px;
  transform: translateX(-50%);
  z-index: 1;
  width: 250px;
  padding: 15px;
  background: a.$white;
  text-align: left;
  // box-shadow: 0 1px 2px rgba(a.$black, 0.22), 0 4px 12px 2px rgba(a.$black, 0.15);
  filter: drop-shadow(0 1px 2px rgba(a.$black, 0.22));
  color: a.$black;

  @include a.font(primary, normal);

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 20px;
    height: 13px;
    background: a.$white;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }

  &__pre-title {
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  &__content {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__close {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

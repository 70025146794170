// Colour Palette
$white: #fff;
$black: #000;

$red: #c90e2f;

$salmon: #febfca;

$olive: #bebca9;
$olive-light: #f3f2e9;

$calendarHighlight: #b0ae9c;
$calendarBackground: #96958b;
$calendarBorderHighlight: #838174;

// Responsive Breakpoints
$breakpoints: (
  xs: 460px,
  mobile: 540px,
  tablet: 740px,
  tablet-wide: 760px,
  desktop: 980px,
  ipad: 1024px,
  wide: 1300px,
  desktop-wide: 1500px,
);

// Fonts
$font-primary: 'EuclidCircularA';
$font-fancy: 'SandBleu';

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 18px;

  @include a.mqmin('desktop') {
    font-size: 30px;
    line-height: 30px;
  }

  & + .counter {
    margin-top: 30px;
  }

  &--sm {
    & + & {
      margin-top: 25px;
    }

    .counter {
      &__field-value,
      &__label {
        font-size: 17px;
        line-height: 17px;
      }
    }
  }

  &__label {
    font-weight: 200;
    font-size: 24px;
    line-height: 22px;

    strong {
      font-weight: bold;
    }
  }

  &__field {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 18px;

    @include a.mqmin('desktop') {
      font-size: 30px;
      line-height: 30px;
    }

    &-button {
      width: 25px;
      height: 25px;
      background: a.$black;
      border-radius: 50%;
      cursor: pointer;
      @include a.tran;

      &:hover {
        background: a.$salmon;

        &::before,
        &::after {
          background: a.$black;
        }
      }

      &--disabled {
        pointer-events: none;
        opacity: .1;
      }
    }

    &-button--up,
    &-button--down {
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 14px;
        background: a.$white;
        border-radius: 3px;
        @include a.tran;
      }
    }

    &-button--up,
    &-button--down {
      &::before {
        transform: translate(-50%, -50%) rotate(90deg);
      }

      &::after {
        transform: translate(-50%, -50%);
      }
    }

    &-button--down::after {
      display: none;
    }

    &-value {
      min-width: 50px;
      text-align: center;
      font-size: 24px;

      @include a.mqmin('desktop') {
        min-width: 80px;
      }
    }
  }

  &.checkbox__label {
    font-size: 15px;
  }
}

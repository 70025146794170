@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.column {
  flex: 0 0 100%;

  @include a.mqmin('desktop') {
    flex: 1;
  }

  &__25 {
    flex: 0 0 25%;
  }

  @include a.mqmin('desktop') {
    &__30 {
      flex: 0 0 30%;
    }
  }

  @include a.mqmin('desktop') {
    &__35 {
      flex: 0 0 35%;
    }
  }

  &__dt_45 {
    @include a.mq('desktop') {
      flex: 0 0 45%;
    }
  }

  @include a.mqmin('desktop') {
    &__50 {
      flex: 0 0 50%;
    }

    &__60 {
      flex: 0 0 60%;
    }

    &__65 {
      flex: 0 0 65%;
    }
  }

  @include a.mqmin('wide') {
    &__61 {
      flex: 0 0 61.4%;
    }
  }

  &__75 {
    flex: 0 0 74.5%;
  }

  &__75 {
    flex: 0 0 74.5%;
  }

  &__100 {
    flex: 0 0 100%;
  }

  &--end,
  &--align-center,
  &--justify-center,
  &--justify-end,
  &--mobile-center {
    display: flex;
  }

  &--align-center {
    align-items: center;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--end {
    align-items: flex-end;
  }

  &--mobile-center {
    justify-content: center;

    @include a.mqmin('tablet') {
      justify-content: flex-end;
    }
  }

  @include a.mqmin('desktop') {
    &--padded-bottom {
      padding-bottom: 130px;
    }

    &--offset-left {
      margin-left: 175px;
      z-index: 1;
    }

    &--offset-left.step-1 {
      margin-left: 177px;
    }

    &--offset-left.step-2 {
      margin-left: 150px;
    }

    &--offset-left.step-3 {
      margin-left: 75px;
    }
  }

  @include a.mqmin('desktop') {
    .grid--gutters & {
      &__50 {
        flex: 0 0 calc(50% - 8px);
      }
    }
  }
}

.column-padding {
  padding-right: 0;
}

@include a.mqmin('tablet-wide') {
  .column--padding {
    padding-right: 30px;
  }
}

@include a.mqmin('ipad') {
  .column--padding {
    padding-right: 48px;
  }
}

@include a.mq('mobile') {
  .column--padding {
    padding-right: 0;
  }
}

@include a.mqmin('wide') {
  .column--padding {
    padding-right: 20px;
  }
}

@include a.mqmin('desktop-wide') {
  .column--padding {
    padding-right: 0;
  }
}

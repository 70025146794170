@font-face {
    font-family: 'EuclidCircularA';
    src: url('../../fonts/euclid-circular-a/EuclidCircularA-Light-WebS.woff2')
            format('woff2'),
        url('../../fonts/euclid-circular-a/EuclidCircularA-Light-WebS.woff')
            format('woff');
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('../../fonts/euclid-circular-a/EuclidCircularA-Regular-WebS.woff2')
            format('woff2'),
        url('../../fonts/euclid-circular-a/EuclidCircularA-Regular-WebS.woff')
            format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('../../fonts/euclid-circular-a/EuclidCircularA-Bold-WebS.woff2')
            format('woff2'),
        url('../../fonts/euclid-circular-a/EuclidCircularA-Bold-WebS.woff')
            format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'SandBleu';
    src: url('../../fonts/sang-bleu/SangBleuEmpire-Medium-WebS.woff2')
            format('woff2'),
        url('../../fonts/sang-bleu/SangBleuEmpire-Medium-WebS.woff')
            format('woff');
    font-weight: normal;
    font-display: swap;
}

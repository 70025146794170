@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.events-calendar-list {
  border-top: 2px solid a.$salmon;
  border-bottom: 2px solid a.$salmon;
  margin: 30px;
  padding: 0 0 30px;

  @include a.mqmin('desktop') {
    padding: 40px 0;
    margin: 0;
  }

  @include a.mq('desktop') {
    .heading__wrapper {
      padding-left: 0;
    }
  }

  &__item {
    display: flex;

    & + & {
      margin-top: 32px;
    }

    &-date {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 0 0 80px;
      width: 80px;
      height: 80px;
      background: a.$white;
      color: a.$calendarBorderHighlight;
      margin-right: 26px;
    }

    &-day {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 10px;
    }

    &-month {
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
    }

    &-title {
      font-size: 18px;
      color: a.$white;
    }
  }
}

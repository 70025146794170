@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.checkbox {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  line-height: 21px;
  position: relative;
  padding-left: 33px;
  z-index: 1;

  @include a.font(primary, 200);

  &__wrap {
    margin-bottom: 24px;

    @include a.mq('desktop') {
      & + .button {
        margin-left: 30px;
      }
    }
  }

  a {
    display: inline-block;
    color: a.$white;
    text-decoration: none;

    &:after {
      content: '';
      display: block;
      height: 2px;
      background-color: a.$salmon;
      margin-top: 4px;
      margin-bottom: -4px;

      @include a.tran;
    }

    &:hover:after {
      background-color: a.$black;
    }
  }

  &__label {
    font-size: 15px;
    
    p {
      display: inline;
    }
  }

  &__check {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    background-color: a.$white;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid a.$black;

    @include a.tran;
  }

  &__input {
    position: absolute;
    visibility: hidden;

    &:checked + .checkbox__check {
      background-color: a.$salmon;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9966 0.330929C11.767 0.233677 11.5145 0.171831 11.2535 0.148927C10.9925 0.126023 10.7281 0.142512 10.4755 0.197449C10.2229 0.252386 9.98698 0.344695 9.78126 0.469096C9.57554 0.593497 9.40404 0.74755 9.27659 0.922446L5.56358 6.00934L3.43858 4.39161C3.25409 4.24619 3.0334 4.1302 2.78939 4.0504C2.54538 3.97061 2.28294 3.92861 2.01738 3.92685C1.75182 3.92509 1.48846 3.96362 1.24267 4.04017C0.996877 4.11673 0.773573 4.22978 0.585787 4.37274C0.398002 4.5157 0.249495 4.6857 0.148933 4.87281C0.0483714 5.05993 -0.00223212 5.26042 7.55135e-05 5.46259C0.00238315 5.66475 0.0575558 5.86455 0.162374 6.0503C0.267192 6.23606 0.419556 6.40407 0.610577 6.54452L4.61058 9.58966C4.98858 9.87818 5.49858 10.0365 6.02458 10.0365L6.30158 10.0213C6.60813 9.98866 6.90055 9.90229 7.1558 9.769C7.41106 9.63571 7.62219 9.45913 7.77258 9.25317L12.7726 2.40162C12.9002 2.22687 12.9814 2.03469 13.0115 1.83605C13.0416 1.63741 13.02 1.4362 12.9479 1.24392C12.8759 1.05163 12.7548 0.87204 12.5916 0.715388C12.4283 0.558737 12.2261 0.428097 11.9966 0.330929Z' fill='black'/%3E%3C/svg%3E");
    }
  }

  &--alt {
    color: a.$white;
    font-size: 16px;
    line-height: 16px;
  }
}

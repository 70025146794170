@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.rates-list {
  margin: 30px 0 0;

  &__heading {
    border-top: 1px solid black;
    padding-top: 30px;
    margin-bottom: 30px;
    font-weight: 200;
    font-size: 18px;
    line-height: 19px;

    @include a.mqmin('ipad') {
      font-size: 19px;
      line-height: 20px;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;

    @include a.mq('ipad') {
      flex-wrap: wrap;
    }

    @include a.mq('ipad') {
      flex-wrap: wrap;
    }

    & + & {
      margin-top: 30px;
    }

    &--seperator {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 2px solid a.$salmon;
    }

    &-content {
      display: flex;

      @include a.mq('ipad') {
        flex-wrap: wrap;
      }
    }

    &-name {
      font-size: 28px;
      line-height: 32px;
      flex: 0 0 50%;

      @include a.font(secondary, normal);

      @include a.mq('wide') {
        flex: 1;
      }
    }

    &-info {
      @include a.mq('wide') {
        flex: 0 0 100%;
        text-align: right;
        margin-top: -65px;
      }

      @include a.mqmin('wide') {
        display: flex;
        align-items: center;
      }

      @include a.mq('wide') {
        order: 1;
      }
    }

    &-price,
    &-name {
      margin-bottom: 20px;

      @include a.mqmin('wide') {
        margin-bottom: 0;
      }
    }

    &-price {
      flex: 1;
      display: flex;
      flex-direction: column;
      font-size: 19px;
      line-height: 25px;
      min-width: 75px;
      margin-top: 20px;

      @include a.mqmin('ipad') {
        margin: 0 50px 0 100px;
      }

      &-uom {
        font-size: 12px;
        line-height: 15px;
        font-weight: normal;
        letter-spacing: 0.2em;
      }
    }

    &-link {
      flex: 0 0 100%;
      margin-top: 20px;

      @include a.mq('wide') {
        margin-bottom: 20px;
        margin-top: 10px;
      }

      @include a.mq('ipad') {
        margin-top: 0;
      }

      &-icon {
        margin-right: 8px;
      }

      .link__label {
        border: none;
      }
    }
  }
}

.radio-button {
  display: inline-block;
  position: relative;
  color: a.$black;
  text-transform: uppercase;
  border: 2px solid a.$black;
  border-radius: 50%;
  height: 46px;
  width: 46px;
  cursor: pointer;
  text-align: center;

  @include a.tran;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 10px;
    border-left: 2px solid a.$black;
    border-bottom: 2px solid a.$black;
    transform: translate(-12px, -8px) rotate(-45deg);
    opacity: 0;

    @include a.tran;
  }

  &:hover,
  &:active,
  &--active {
    background: a.$salmon;
  }

  &:active,
  &--active {
    &::before {
      opacity: 1;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.grid {
  display: flex;
  flex-wrap: wrap;

  @include a.mq('desktop') {
    &--flip-columns {
      flex-direction: column-reverse;
    }
  }

  &--space-between {
    justify-content: space-between;
  }

  &--end {
    justify-content: flex-end;
  }

  @include a.mqmin('desktop') {
    &--overlap {
      margin-top: -50px;
    }

    &--overlap-lg {
      margin-top: -65px;
    }

    &--overlap-xxl {
      margin-top: -240px;
    }
  }

  @include a.mqmin('desktop') {
    &--spaced-bottom-tiny {
      margin-bottom: 20px;
    }

    &--spaced-bottom-xxs {
      margin-bottom: 30px;
    }

    &--spaced-bottom-xs {
      margin-bottom: 50px;
    }

    &--spaced-bottom-sm {
      margin-bottom: 70px;
    }

    &--spaced-bottom {
      margin-bottom: 96px;
    }

    &--spaced-bottom-lg {
      margin-bottom: 130px;
    }

    &--offset-left {
      margin-left: -150px;

      & > .column:first-of-type {
        padding-right: 75px;
      }
    }
  }

  &--gutters {
    justify-content: space-between;

    .field__wrap {
      padding: 0 30px;
      margin-bottom: 30px;
      max-width: 100%;

      @include a.mqmin('desktop') {
        padding: 0;
        margin-bottom: 38px;
      }
    }
  }
}

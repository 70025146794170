@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.events-list {
  &__card {
    display: flex;
    margin-bottom: 15px;

    & + & {
      margin-bottom: 48px;
    }
  }

  &__image,
  &__content {
    width: 50%;
  }

  &__image {
    position: relative;
    z-index: 1;
    overflow: hidden;

    img {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: a.$olive-light;
    padding: 40px 20px;
  }

  &__heading {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px;
  }

  &__date {
    font-size: 14px;
    line-height: 20px;
  }

  &__error {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .heading__main {
    font-size: 16px;
    text-transform: uppercase;
  }

   @include a.mq('mobile') {
     .heading__wrapper {
       padding: 20px 0;
     }
   }
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.counter-group {
  & + & {
    margin-top: 48px;
  }

  & + .room-card__rate {
    margin-top: 25px;
  }

  &__heading {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-bottom: 10px;
  }

  &--border-top {
    border-top: 2px solid a.$olive-light;
    padding-top: 48px;
  }

  &--narrow {
    @include a.mqmin('wide') {
      margin-right: 215px;
    }
  }

}

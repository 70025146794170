@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 75px;
    height: 75px;
    border: 5px solid a.$white;
    transform: translate(-50%, -50%);
    animation: spinner 1s linear infinite;
    border-radius: 50%;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 35px;
        width: 50px;
        background: a.$olive;
        transform: translate(0%, -50%);
    }
}

@keyframes spinner {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.card {
  position: relative;
  padding: 30px;
  background: a.$white;

  @include a.mqmin('desktop') {
    padding: 48px;
  }

  .field__wrap .field__label {
    color: a.$black;
  }

  &--top-triangle {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -8px;
      left: 85px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 8px 9px;
      border-color: transparent transparent a.$white transparent;

      @include a.mqmin('tablet') {
        left: unset;
        right: 62px;
      }
    }
  }
}

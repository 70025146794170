@use '../abstracts/_abstracts-dir' as a;

* {
  box-sizing: border-box;
}

html,
body {
  background: a.$olive;
  font-size: 16px;

  @include a.font(primary, normal);
}

.flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

img {
  max-width: 100%;
}

.space-top {
  margin-top: 48px;

  &--sm {
    margin-top: 30px;
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.text-right {
  text-align: right;
}

html,
body,
#root {
  height: 100%;
}

#root {
  // overflow-x: hidden;
  // margin-bottom: 130px;
}

div[class^='sc-'] {
  overflow: visible;
}

@include a.mq('tablet') {
  .hide-me-on-tablet {
    display: none !important;
  }
}

@include a.mqmin('tablet') {
  .show-me-on-tablet {
    display: none !important;
  }
}

@include a.mq('desktop') {
  .space-me-out-horizontally-on-tablet {
    padding: 0 30px;
  }
}

@include a.mqmin('desktop') {
  .show-me-on-desktop {
    display: none !important;
  }
}

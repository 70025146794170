@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include a.mq('tablet') {
        flex-direction: column;
    }

    &.container {
        padding: 50px 15px 95px 15px;

        @include a.mq('tablet') {
            padding: 20px 15px 50px 15px;
        }
    }

    &__logo {
        margin-bottom: 30px;
    }
}

@use './assets/scss/base/_base-dir';
@use './assets/scss/components/_components-dir';

@import 'animate.css';

.Step {
  padding-bottom: 130px;
}

.ReactJsonSyntaxHighlighter {
  position: fixed;
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: #111;
  padding: 5px;
  z-index: 9999999;
  transform: translateX(-80%);
  transition: all .3s ease;

  &.fixed {
    width: 25%;
    transform: unset;
  }

  &:hover {
    transform: translateX(0);
  }
}
.ReactJsonSyntaxHighlighter pre {
  font-size: 9pt;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  color: #4398ff;
}
.ReactJsonSyntaxHighlighter .string {
  color: #ff7de9;
}
.ReactJsonSyntaxHighlighter .number {
  color: #86b345;
}
.ReactJsonSyntaxHighlighter .boolean {
  color: #86ca52;
}
.ReactJsonSyntaxHighlighter .null {
  color: #f0f;
}
.ReactJsonSyntaxHighlighter .key {
  color: #4398ff;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

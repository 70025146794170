@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.container {
    max-width: 1190px;
    margin: 0 auto;

    @include a.mq('wide') {
        max-width: 950px;
    }

    &--narrow {
        max-width: 1030px;
    }

    &--lg {
        width: 100%;
        max-width: 1432px;
        margin: 0 auto;
    }
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.rooms-sidebar {
  padding: 30px 0;
  margin: 30px;
  color: a.$white;

  & + & {
    border-top: 2px solid a.$salmon;
  }

  @include a.mqmin('desktop') {
    padding: 60px 0 0 0;
    margin-top: 0;
    margin-left: 155px;
    margin-right: 50px;
  }

  @include a.mqmin('wide') {
    padding: 60px 0 0 0;
    margin-top: 0;
    margin-left: 205px;
    margin-right: 0;
  }

  &__heading {
    .heading {
      &__main--xs {
        font-size: 34px;
        line-height: 40px;
        padding-right: 50px;
      }

      &__pre {
        font-size: 15px;
        padding-bottom: 5px;
        margin-bottom: 20px;
        font-weight: lighter;
      }
    }
  }

  &__sub-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 30px;

    @include a.font(primary, 200);
  }

  &__card {
    display: flex;

    @include a.mq('wide') {
      margin-right: 16px;
    }

    @include a.mqmin('desktop') {
      display: block;
    }

    @include a.mqmin('wide') {
      display: flex;
    }

    & + & {
      margin-top: 30px;
    }

    &-img {
      flex: 0 0 80px;
      margin-right: 16px;

      @include a.mqmin('desktop') {
        flex: 0 0 120px;
      }

      img {
        max-width: 150px;
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
    }

    &-title {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 13px;
      text-transform: uppercase;
      letter-spacing: 1px;

      @include a.mq('wide') {
        line-height: 20px;
      }
    }

    &-content {
      font-size: 14px;
      line-height: 22px;
      font-weight: 200;
    }
  }
}

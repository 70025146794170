@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.field__wrap {
  display: inline-block;
  width: 100%;
  position: relative;
  max-width: 370px;

  .field {
    display: block;
    width: 100%;
    height: 48px;
    padding: 0 15px;
    font-size: 18px;
    background-color: a.$white;
    border-radius: 0;
    box-shadow: none;

    @include a.font(primary, normal);

    &::-webkit-input-placeholder {
      color: rgba(a.$olive, 0.5);
    }

    &:-ms-input-placeholder {
      color: rgba(a.$olive, 0.5);
    }

    &::placeholder {
      color: rgba(a.$olive, 0.5);
    }

    &:focus {
      border-radius: 0;
      border: 1px solid a.$white;
      outline: none;
    }

    &--bordered {
      border: 1px solid a.$black;

      &:focus {
        border: 1px solid a.$black;
      }
    }

    &__label {
      display: block;
      margin-bottom: 10px;
      font-weight: 200;
      color: a.$white;
    }

    &__required {
      position: absolute;
      top: 0;
      right: 30px;
      font-size: 14px;
      color: a.$calendarBorderHighlight;

      @include a.mqmin('desktop') {
        right: 0;
      }
    }
  }

  textarea.field {
    height: 224px;
    resize: none;
    padding: 15px;
    background-color: white;
    outline: none;
    border: none;
  }

  &__validation-message {
    margin-top: 15px;
    font-size: 14px;
    line-height: 14px;

    &--success {
      color: a.$olive;
    }

    &--failed {
      color: a.$red;
    }
  }
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

@keyframes loader {
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

.button {
  display: inline-block;
  color: a.$black;
  text-transform: uppercase;
  border: 2px solid a.$salmon;
  font-size: 12px;
  background: transparent;
  padding: 0 37px;
  line-height: 46px;
  height: 46px;
  letter-spacing: 0.2em;
  cursor: pointer;
  text-decoration: none;
  @include a.font(primary, normal);

  @include a.mqmin('tablet') {
    font-size: 14px;
  }

  @include a.tran;

  &:hover,
  &:active {
    background: a.$salmon;
  }

  &--alt {
    color: a.$white;
  }

  &__text {
    color: a.$white;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin-top: 10px;
    max-width: 300px;
  }

  &--small {
    padding: 0 5px;
    line-height: 17px;
    height: 20px;
    letter-spacing: 0;
    font-size: 10px;
  }

  &--disabled {
    opacity: .6;
    pointer-events: none;
  }

  &--wide {
    display: block;
    max-width: 250px;
    text-align: center;
    margin: 0 auto;
  }

  &--loader {
    position: relative;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 15px;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
      border-radius: 50%;
      animation: loader 1s linear forwards infinite;
      box-sizing: border-box;
      border-top: 2px solid a.$salmon;
      border-right: 2px solid transparent;
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      &::after {
        border-top-color: a.$white;
      }
    }
  }

  &--loading {
    padding-right: 55px;

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

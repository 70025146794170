@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.room-card__gallery {
  .carousel.carousel-slider {
    .control-arrow {
      top: 20px;
      left: unset;
      bottom: unset;
      width: 43px;
      height: 43px;
      border-radius: 50%;
      opacity: 1;
      background: transparent;
      border: 1px solid a.$white;

      &:before {
        display: none;
      }

      &.control-next {
        right: 20px;
        background: url('../../assets/images/icons/arrow-right-alt.svg') no-repeat
          center transparent;
        background-size: 9px;
      }

      &.control-prev {
        right: 70px;
        background: url('../../assets/images/icons/arrow-left-alt.svg') no-repeat
          center transparent;
        background-size: 9px;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.alert {
  margin-bottom: 30px;
  padding: 20px;
  background: a.$olive-light;
  font-size: 14px;

  @include a.mqmin('tablet') {
    font-size: 16px;
  }

  .link {
    font-size: 11px;

    @include a.mqmin('tablet') {
      font-size: 14px;
    }
  }
}

@use './variables' as v;

// Fonts
@mixin font($font, $weight: normal) {
    @if $font == primary {
        font-family: v.$font-primary;
        font-weight: $weight;
    }

    @if $font == secondary {
        font-family: v.$font-fancy;
        font-weight: $weight;
    }
}

// Transition
@mixin tran($target: all, $speed: 275ms, $timing-function: ease, $delay: 0ms) {
    -webkit-transition: $target $speed $timing-function $delay;
    -moz-transition: $target $speed $timing-function $delay;
    -o-transition: $target $speed $timing-function $delay;
    -ms-transition: $target $speed $timing-function $delay;
    transition: $target $speed $timing-function $delay;
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.picture-card {
  display: flex;
  padding: 30px;
  flex-wrap: wrap;

  @include a.mqmin('desktop') {
    padding: 0;
  }

  & + & {
    @include a.mqmin('desktop') {
      margin-top: 50px;
    }
  }

  &__img {
    @include a.mq('mobile') {
      margin-bottom: 30px;
    }
    @include a.mqmin('mobile') {
      flex: 0 0 35%;
    }
  }

  &__content {
    @include a.mqmin('mobile') {
      flex: 0 0 65%;
      padding-left: 40px;
    }
  }

  .heading__main {
    margin-bottom: 15px;
  }

  @include a.mq('desktop') {
    .heading__wrapper {
      padding-left: 0;
      padding-top: 0;
    }
  }

  .link {
    margin-top: 8px;
  }
}

@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.stay-summary {
  padding: 30px;

  &__row {
    @include a.mqmin('mobile') {
      display: flex;
      flex-wrap: wrap;
    }

    &.underline {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 2px solid rgba(a.$olive-light, 0.25);
    }

    & + & {
      margin-top: 20px;
    }

    & + .heading__wrapper,
    & + .price {
      margin-top: 30px;
    }
  }

  &__col {
    flex: 0 0 50%;

    &.text-right {
      text-align: left;

      @include a.mqmin('tablet') {
        text-align: right;
      }
    }

    &--100 {
      flex: 0 0 100%;
    }
  }

  .heading__wrapper {
    padding: 0;
  }
}

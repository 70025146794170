@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.rte {
    color: a.$white;
    font-size: 16px;
    line-height: 25px;
    @include a.font(primary, 200);

    & > * + * {
        margin-top: 10px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        display: block;
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 25px;
        @include a.font(primary, 200);
    }

    ul {
        padding-left: 18px;

        li {
            list-style: disc;
        }
    }

    &--dark {
        color: a.$black;
    }

    &--sm {
        margin-top: 10px;
        font-size: 14px;
        line-height: 22px;
    }

    &--no-space {
        margin-top: 0;
    }
}

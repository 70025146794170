@use '../../assets/scss/abstracts/_abstracts-dir' as a;

.room-card {
  display: flex;
  flex-wrap: wrap;
  color: a.$white;
  padding: 0;
  width: calc(100% + 30px);

  @include a.mqmin('ipad') {
    width: calc(100% + 30px);
  }

  & + & {
    margin-top: 64px;
  }

  @include a.mqmin('ipad') {
    margin-left: 75px;
  }

  @include a.mqmin('wide') {
    margin-left: 0;
  }

  & + .card {
    margin-bottom: 64px;
    padding-right: 30px;

    @include a.mqmin('wide') {
      margin-top: -30px;
      transform: translateX(30px);
    }
  }

  &__gallery {
    flex: 100%;

    @include a.mqmin('desktop') {
      flex: 0 0 468px;
      margin-right: 48px;
    }
  }

  &__content {
    flex: 1;
    padding: 30px;

    @include a.mqmin('desktop') {
      padding: 0;
      display: block;
    }
  }

  &__name {
    font-size: 48px;
    line-height: 48px;
    margin: 10px 0;

    @include a.font(secondary, normal);
  }

  &__dog {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
    @include a.font(primary, 200);

    img {
      margin-right: 10px;
    }
  }

  &__description {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    max-height: 50px;
    overflow: hidden;

    &--active {
      max-height: unset;

      &::before {
        display: none;
      }
    }
  }

  &__description-toggle {
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    margin-top: 8px;
    margin-bottom: 30px;
    cursor: pointer;
    letter-spacing: 0.2em;

    @include a.font(primary, 200);

    img {
      margin-right: 8px;
    }

    &--active {
      img {
        transform: rotate(180deg);
      }
    }
  }

  &__pricing {
    &-wrap {
      @include a.mqmin('tablet') {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;
      }
    }

    &-label,
    &-qty {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 5px;
    }

    &-label {
      letter-spacing: 0.2em;

      @include a.font(primary, 200);
    }

    &-price {
      display: flex;
      align-items: baseline;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 20px;

      &-decimal {
        font-size: 12px;
        transform: translate(2px, 5px);
      }

      @include a.mqmin('tablet') {
        margin-bottom: 0;
      }
    }

    &-qty {
      margin: 0;
      margin-left: 12px;
      letter-spacing: 0.2em;

      @include a.font(primary, 200);
    }
  }

  &__rate {
    background: rgb(245, 245, 245);

    padding: 24px;

    @include a.mqmin('desktop') {
      padding: 48px;
    }

    & + .grid {
      margin-top: 20px;
    }

    & + & {
      border-top: 2px solid a.$olive-light;
      padding-top: 48px;
    }
  }

  &__select-overlay {
    .counter__label {
      font-size: 17px;
    }

    .counter-group--number-of-rooms {
      padding: 0 24px 0 0;

      @include a.mqmin('ipad') {
        padding: 0 48px 0 0;
      }
    }
  }
}
